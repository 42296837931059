import React from 'react';
import { Link } from 'react-router-dom';

const AboutUs = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-gray-800 to-black text-white">
      {/* Logo */}
      <div className="mb-6">
        <Link to="/">
          <img src="/myvibelogowithtagline.png" alt="Logo" className="w-132 h-32" />
        </Link>
      </div>

      {/* Heading */}
      <h1 className="text-5xl font-bold mb-6 text-center">About Us</h1>

      {/* Content */}
      <div className="max-w-3xl text-center space-y-6 text-lg">
        <p>
          <span className="font-bold text-blue-400">Hello, and welcome to myVibe.live!</span>
        </p>
        <p>
          myVibe.live was born out of a friendship between two music fans, Mike and Matt, who met while working at the same company after college. We’re part of a close-knit group of friends who share countless passions—playing poker, cheering for the Packers, Brewers, Bucks, and Badgers, annual canoe trips, and even family vacations. But one passion unites us all: our love of music.
        </p>
        <p>
          Our group’s musical tastes are as diverse as they come, from mellow vibes to head-banging rock. Music is music, and we love it all. We have group chats dedicated to new Spotify finds, shared playlists we all contribute to, and most importantly, we jump at every chance to see our favorite artists live.
        </p>
        <p>
          For us, the Mile of Music festival in Appleton, WI, is a highlight of the year. Over four unforgettable days, hundreds of original artists perform across venues along a one-mile stretch of College Avenue. Every year, we find ourselves digging through Spotify, trying to figure out which bands we’ve saved and when they’re playing. One night, while talking in Matt’s man cave, we realized: finding live music should be easier. And that’s when <span className="font-bold text-blue-400"><Link to="/">myVibe.live</Link></span> was born.
        </p>
        <p>
          We envisioned a platform where fans could search for live music by genre and location. Then the ideas started rolling: sharing events with friends, discovering new artists, and more. Inspired by the vision, Matt dove headfirst into coding to bring it to life—a true passion project built from the ground up.
        </p>
        <p>
          We want myVibe.live to help you discover incredible live music and create memories that last a lifetime. Whether it’s a Friday night show at a local venue or a festival performance you’ll never forget, we aim to make finding live music seamless and exciting. There’s so much talent out there—artists playing in the next town over or just down the block. <span className="font-bold text-blue-400"><Link to="/">myVibe.live</Link></span> wants to connect you to them.
        </p>
        <p>
          Thank you for checking us out! Follow us on social media, share your favorite shows, and let us know who rocked your world. We’re always looking for the next great live performance—and we hope myVibeLive becomes your go-to guide for discovering it.
        </p>
      </div>

      {/* Footer */}
      <div className="mt-12">
        <p className="text-sm text-gray-400">
          Interested in partnering with us? Reach out via our <a href="/contactus" className="text-blue-400 underline">Contact Us</a> page.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;