import './app.css';
import Search from './components/Search';
import ShowCard from './components/ShowCard';
import Footer from './components/Footer';
import NoShows from './components/NoShows';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchShows, logEvent } from './lib/data';
import { Show, Venue, Artist, Genre } from "./lib/definitions";
import SearchBar from './components/SearchBar';
import MainHeader from './components/MainHeader';

function App() {
  const defaultDistance = 'Within 25 Miles'; 
  const defaultTimePeriod = 'Next 7 Days'; 

  const [selectedGenre, setSelectedGenre] = useState<string | null>(null);
  const [selectedGenreId, setSelectedGenreId] = useState<number | null>(null);
  const [selectedDistance, setSelectedDistance] = useState<string | null>(defaultDistance);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string | null>(defaultTimePeriod);

  const [userLat, setUserLat] = useState<string | null>(null);
  const [userLon, setUserLon] = useState<string | null>(null);
  const [locationError, setLocationError] = useState<string | null>(null);
  const [locationMethod, setLocationMethod] = useState<string | null>(null);

  const searchParams = new URLSearchParams(window.location.search);
  const showkeyFromUrl = searchParams.get('showkey') || '';

  const [showkey, setShowkey] = useState<string>(showkeyFromUrl);

  useEffect(() => {
    if (!showkeyFromUrl) {
      setShowkey(''); // Reset the showkey if not in the URL
    }
  }, [showkeyFromUrl]);

  // Reset showkey when filters are used
  const handleFilterChange = () => {
    setShowkey(''); // Clear showkey when the user uses filters
  };

  // Function to get location from HTML5 Geolocation API
  const getBrowserLocation = async () => {
    return new Promise<{ lat: number, lon: number }>((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            });
          },
          (error) => {
            reject(new Error('Failed to retrieve browser location: ' + error.message));
          }
        );
      } else {
        reject(new Error('Geolocation is not supported by this browser.'));
      }
    });
  };

  // Function to get location from IP-based geolocation service (fallback)
  const getIpBasedLocation = async () => {
    try {
      const ip = await getPublicIp();
      const apiKey = process.env.REACT_APP_MAXMIND_KEY; // Use your MaxMind API key here
      const response = await fetch(`https://geoip.maxmind.com/geoip/v2.1/city/${ip}?key=${apiKey}`);
      if (!response.ok) {
        throw new Error('Failed to retrieve IP-based location');
      }
      const locationData = await response.json();
      return {
        lat: locationData.location.latitude,
        lon: locationData.location.longitude,
      };
    } catch (error) {
      if (error instanceof Error) {
        throw new Error('IP-based Geolocation Error: ' + error.message);
      } else {
        throw new Error('IP-based Geolocation Error: Unknown error occurred');
      }
    }
  };

  // Function to get location from Google Geolocation API (last fallback)
  const getLocationFromGoogle = async () => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY; // Use your Google API key here
      const response = await fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Failed to retrieve location from Google API');
      }

      const data = await response.json();
      if (data.location) {
        const lat = data.location.lat;
        const lon = data.location.lng;
        return { lat, lon };
      } else {
        throw new Error('Google Geolocation API did not return location');
      }
    } catch (error) {
      if (error instanceof Error) {
        throw new Error('Google Geolocation API Error: ' + error.message);
      } else {
        throw new Error('Google Geolocation API Error: Unknown error occurred');
      }
    }
  };

  // Function to get user's public IP address
  const getPublicIp = async () => {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip;
  };

// Main function to try all methods to get location
const getLocation = async () => {
  try {
    // Try to get the location from the browser first (most accurate on mobile with GPS)
    const browserLocation = await getBrowserLocation();
    setUserLat(browserLocation.lat.toString());
    setUserLon(browserLocation.lon.toString());
    setLocationMethod('Browser');
    return;
  } catch (error) {
    console.error('Error retrieving browser location:', error);
  }

  try {
    // If browser location fails, fallback to Google Geolocation (Wi-Fi/cell tower triangulation)
    const googleLocation = await getLocationFromGoogle();
    setUserLat(googleLocation.lat.toString());
    setUserLon(googleLocation.lon.toString());
    setLocationMethod('Google');
    return;
  } catch (error) {
    console.error('Error retrieving Google location:', error);
  }

  try {
    // If both browser and Google Geolocation fail, fallback to IP-based location (least accurate)
    const ipLocation = await getIpBasedLocation();
    setUserLat(ipLocation.lat.toString());
    setUserLon(ipLocation.lon.toString());
    setLocationMethod('IP');
  } catch (error) {
    console.error('Error retrieving IP-based location:', error);
  }
};

  useEffect(() => {
    getLocation();
  }, []);

  const { data: shows, error, isLoading } = useQuery<Show[], Error>({
    queryKey: [
      'shows', 
      showkey, 
      selectedGenreId,
      selectedDistance, 
      selectedTimePeriod, 
      userLat, 
      userLon
    ],
    queryFn: () => fetchShows(
      showkey, 
      null,
      null,
      selectedGenreId,
      selectedDistance, 
      selectedTimePeriod, 
      userLat, 
      userLon
    ),
    enabled: !!userLat && !!userLon,  // Only fetch shows when location is available
  });

  if (locationError) return <div>Error: {locationError}</div>;

  if (isLoading || !userLat || !userLon) return (
    <>
      <MainHeader />
      <div className="flex flex-col items-center justify-center space-y-4">
        <img 
          src="logo-animate.gif" 
          alt="Animated Logo" 
          className="max-w-[20rem] md:max-w-[25rem] lg:max-w-[30rem] object-contain" 
        />
      </div>
      <Footer />
    </>
  );

  if (error) return <div>Error: {error.message}</div>;

  return (
    <main className="flex overflow-visible flex-col justify-end mx-auto w-full bg-neutral-200">
      <MainHeader />
      {
        showkey ? (
          <header className="flex overflow-visible flex-col px-4 pt-8 pb-4 w-full font-medium leading-6 text-center bg-zinc-950">
            <SearchBar />
          </header>
        ) : (
        <Search
          selectedGenre={selectedGenre}
          setSelectedGenre={setSelectedGenre}
          selectedGenreId={selectedGenreId}
          setSelectedGenreId={setSelectedGenreId}
          selectedDistance={selectedDistance}
          setSelectedDistance={setSelectedDistance}
          selectedTimePeriod={selectedTimePeriod}
          setSelectedTimePeriod={setSelectedTimePeriod}
          userLat={userLat}           // Pass userLat
          userLon={userLon}           // Pass userLon
          setUserLat={setUserLat}     // Pass setUserLat
          setUserLon={setUserLon}     // Pass setUserLon
        />
        )
      }
      <div className="flex flex-col self-center pt-8 w-full max-w-[750px]">
        {shows && shows.length > 0 ? (
          shows.map(show => {
            const venues: Venue[] = Array.isArray(show.venues) ? show.venues : [];
            const genres: Genre[] = Array.isArray(show.genres) ? show.genres : [];
            const artists: Artist[] = Array.isArray(show.artists) ? show.artists : [];

            return (
              <ShowCard
                key={show.show_id}
                name={show.name || ''}
                date={show.date_start || ''}
                time={show.time_start || ''}
                show={show}
                venue={venues}
                genres={genres}
                artists={artists}
              />
            );
          })
        ) : (
          <NoShows 
            userLat={userLat}
            userLon={userLon}
            setUserLat={setUserLat} // Pass the setter function
            setUserLon={setUserLon} // Pass the setter function
          />  
        )}
      </div>
      <Footer />
    </main>
  );
}

export default App;