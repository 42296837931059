import React from "react";
import SearchBar from "./SearchBar";
import FilterButtons from "./FilterButtons";

interface FilterButtonsProps {
  selectedGenre: string | null;
  setSelectedGenre: React.Dispatch<React.SetStateAction<string | null>>;
  selectedGenreId: number | null;
  setSelectedGenreId: React.Dispatch<React.SetStateAction<number | null>>;  
  selectedDistance: string | null;
  setSelectedDistance: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTimePeriod: string | null;
  setSelectedTimePeriod: React.Dispatch<React.SetStateAction<string | null>>;
  userLat: string | null;   // Add userLat
  userLon: string | null;   // Add userLon
  setUserLat: React.Dispatch<React.SetStateAction<string | null>>;   // Add userLat setter
  setUserLon: React.Dispatch<React.SetStateAction<string | null>>;   // Add userLon setter
}

const Search: React.FC<FilterButtonsProps> = ({
  selectedGenre,
  setSelectedGenre,
  selectedGenreId,
  setSelectedGenreId,
  selectedDistance,
  setSelectedDistance,
  selectedTimePeriod,
  setSelectedTimePeriod,
  userLat,   // Receive userLat
  userLon,   // Receive userLon
  setUserLat,   // Receive userLat setter
  setUserLon    // Receive userLon setter
}) => {
  return (
    <header className="flex overflow-visible flex-col px-4 pt-2 pb-4 w-full font-medium leading-6 text-center bg-zinc-950">
      <SearchBar />
      <FilterButtons 
        selectedGenre={selectedGenre}
        setSelectedGenre={setSelectedGenre}
        selectedGenreId={selectedGenreId}
        setSelectedGenreId={setSelectedGenreId}        
        selectedDistance={selectedDistance}
        setSelectedDistance={setSelectedDistance}
        selectedTimePeriod={selectedTimePeriod}
        setSelectedTimePeriod={setSelectedTimePeriod}
        userLat={userLat}    // Pass userLat to FilterButtons
        userLon={userLon}    // Pass userLon to FilterButtons
        setUserLat={setUserLat}  // Pass setUserLat to FilterButtons
        setUserLon={setUserLon}  // Pass setUserLon to FilterButtons
      />
    </header>
  );
};

export default Search;