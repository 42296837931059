import React, { useState } from "react";

export const MainHeader: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
      <header
        className="flex flex-col justify-center bg-zinc-950 pt-2 pb-2 overflow-hidden"
        style={{
          borderBottom: "none", // Remove border
          boxShadow: "none", // Remove shadow
          transform: "translateZ(0)", // Prevent rendering artifacts
          zIndex: 10, // Ensure correct stacking
        }}
      >
      <div className="flex items-center justify-between w-full px-8 max-md:px-5">
        {/* Logo */}
        <a href="/">
          <img
            loading="lazy"
            src="myvibelive logo.png"
            alt="Logo"
            className="w-[100px]"
          />
        </a>

        {/* Hamburger Menu Icon for Mobile */}
        <button
          className="block md:hidden text-neutral-200"
          onClick={toggleMenu}
        >
          {menuOpen ? "✖" : "☰"} {/* Menu icon toggles between "X" and "≡" */}
        </button>

        {/* Navigation Links (Desktop) */}
        <nav className="hidden md:flex gap-8 items-center text-neutral-200 ml-auto">
          {/* 
          <a href="/" className="text-neutral-200 cursor-pointer">
            Venue & Artist Directory
          </a>
          */}
          <a href={`/Search`} className="text-neutral-200 cursor-pointer">
            Show Search
          </a>
          {/* 
          <a href="/about" className="text-neutral-200 cursor-pointer">
            About Us
          </a>*/}
          <a href="/workwithus" className="text-neutral-200 cursor-pointer">
            Work with us
          </a>
          <a href="/login" className="gap-2 px-6 py-1 text-sm font-medium leading-6 text-center text-white rounded-md border border-solid border-zinc-700">
            Artist / Venue Login
          </a>
        </nav>
      </div>

      {/* Dropdown Menu (Mobile) */}
      {menuOpen && (
        <div className="flex flex-col items-start bg-zinc-950 px-5 py-3 w-full md:hidden">
          {/* 
          <a
            href="/"
            className="text-neutral-200 cursor-pointer py-2 w-full text-left"
          >
            Venue & Artist Directory
          </a>
          */}
          <a
            href={`/Search`}
            className="text-neutral-200 cursor-pointer py-2 w-full text-left"
          >
            Show Search
          </a>
          {/* 
          <a
            href="/about"
            className="text-neutral-200 cursor-pointer py-2 w-full text-left"
          >
            About Us
          </a>*/}
          <a
            href="/workwithus"
            className="text-neutral-200 cursor-pointer py-2 w-full text-left"
          >
            Work with us
          </a>
          <a
            href="/login"
            className="text-neutral-200 cursor-pointer py-2 w-full text-left"
          >
            Artist / Venue Login
          </a>
        </div>
      )}
    </header>
  );
};

export default MainHeader;