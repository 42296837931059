import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { fetchVenueNotificationByKey, fetchArtistNotificationByKey, updateNotifications } from '../lib/data';
import MainHeader from '../components/MainHeader';
import Footer from '../components/Footer';
import { Check } from 'lucide-react';

// Interfaces
export interface ArtistItemProps {
  name: string;
  artist_name?: string;
  selected?: boolean;
  identifier?: string;
  notification_id?: number;
}

export interface VenueItemProps {
  name: string;
  addr_city?: string;
  addr_st?: string;
  location?: string;
  selected?: boolean;
  identifier?: string;
  notification_id?: number;
}

interface SelectableItemProps {
  name: string;
  selected?: boolean;
  location?: string;
  onSelect?: () => void;
}

const SelectableItem: React.FC<SelectableItemProps> = ({ name, selected = true, location, onSelect }) => (
  <div
    className="flex gap-2 items-center mt-4 text-sm font-semibold text-center text-white cursor-pointer"
    onClick={onSelect}
  >
    <div
      className={`flex items-center justify-center w-5 h-5 border-2 rounded-full ${
        selected ? 'bg-white border-white' : 'border-gray-400'
      }`}
    >
      {selected && <Check className="w-3 h-3 text-black" />}
    </div>
    <div className="ml-3 self-stretch my-auto">
      {name}
      {location && `, ${location}`}
    </div>
  </div>
);

const Notifications: React.FC = () => {
  const [searchParams] = useSearchParams();
  const notificationKey = searchParams.get('notification_key');
  const [email, setEmail] = useState('');
  const [artists, setArtists] = useState<ArtistItemProps[]>([]);
  const [venues, setVenues] = useState<VenueItemProps[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    const fetchNotifications = async () => {
      if (!notificationKey) {
        setLoading(false);
        return;
      }

      try {
        setLoading(true);

        const [artistResponse, venueResponse] = await Promise.all([
          fetchArtistNotificationByKey(notificationKey),
          fetchVenueNotificationByKey(notificationKey),
        ]);

        const artistData = artistResponse?.data || [];
        const venueData = venueResponse?.data || [];

        setEmail(artistData[0]?.identifier || venueData[0]?.identifier || '');

        setArtists(
          artistData.map((artist) => ({
            ...artist,
            selected: true,
            notification_id: artist.notification_id,
          }))
        );

        setVenues(
          venueData.map((venue) => ({
            ...venue,
            selected: true,
            notification_id: venue.notification_id,
            location: venue.addr_city && venue.addr_state ? `${venue.addr_city}, ${venue.addr_state}` : '',
          }))
        );
      } catch (err) {
        console.error('Error fetching notifications:', err);
        setError('Failed to fetch notifications');
      } finally {
        setLoading(false);
      }
    };

    fetchNotifications();
  }, [notificationKey]);

  const toggleArtistSelection = (index: number) => {
    setArtists((prevArtists) =>
      prevArtists.map((artist, i) =>
        i === index ? { ...artist, selected: !artist.selected } : artist
      )
    );
  };

  const toggleVenueSelection = (index: number) => {
    setVenues((prevVenues) =>
      prevVenues.map((venue, i) =>
        i === index ? { ...venue, selected: !venue.selected } : venue
      )
    );
  };

  const handleSendLink = () => {
    if (email.trim() === '') {
      setMessage('Please provide an email address to send the link.');
      return;
    }
    setMessage('A notification link was sent to your email.');
  };

  const handleSavePreferences = async () => {
    const inactiveArtistIds = artists
      .filter((artist) => !artist.selected)
      .map((artist) => artist.notification_id)
      .filter((id): id is number => id !== undefined);

    const inactiveVenueIds = venues
      .filter((venue) => !venue.selected)
      .map((venue) => venue.notification_id)
      .filter((id): id is number => id !== undefined);

    const notificationIds = [...inactiveArtistIds, ...inactiveVenueIds];

    if (notificationIds.length > 0) {
      try {
        await updateNotifications(notificationIds, false);
        setMessage('Your preferences have been saved successfully!');
      } catch (err) {
        console.error('Error saving preferences:', err);
      }
    } else {
      setMessage('No changes made to your preferences.');
    }
  };

  const handleUnsubscribeAll = async () => {
    const allNotificationIds = [...artists, ...venues]
      .map((item) => item.notification_id)
      .filter((id): id is number => id !== undefined);

    if (allNotificationIds.length > 0) {
      try {
        await updateNotifications(allNotificationIds, false);
        setMessage('You have unsubscribed from all notifications.');
        setArtists([]);
        setVenues([]);
        setShowConfirm(false);
      } catch (err) {
        console.error('Error unsubscribing from all:', err);
      }
    }
  };

  const handleConfirmUnsubscribe = () => setShowConfirm(true);

  const handleCancelUnsubscribe = () => setShowConfirm(false);

  if (loading) {
    return <div className="text-white text-center">Loading notifications...</div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  return (
    <>
      <MainHeader />
      <div className="flex overflow-hidden flex-col px-16 py-28 max-md:px-5 max-md:py-24 bg-zinc-950">
        <div className="flex flex-col w-full text-white max-md:max-w-full">
          <h1 className="self-stretch text-6xl font-bold tracking-tighter leading-none max-md:max-w-full max-md:text-4xl">
            Manage Notifications
          </h1>
          {!notificationKey && (
            <p className="mt-4 text-lg text-white">
              Enter your email to receive your notification link.
            </p>
          )}
          <input
            type="email"
            id="emailInput"
            className="flex gap-2 mt-6 w-1/2 rounded-md border border-solid bg-zinc-700 border-zinc-700 min-h-[40px] max-md:max-w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            readOnly={!!notificationKey}
          />
        {!notificationKey && (
        <>
            <button
            className="gap-2 self-start px-6 py-4 mt-4 text-white rounded-md border border-solid bg-zinc-950 border-zinc-700 max-md:px-5"
            type="button"
            onClick={handleSendLink}
            >
            Send Notification Link
            </button>
            {message && (
            <p className="mt-4 text-white">{message}</p>
            )}
        </>
        )}
        </div>
  
        {notificationKey && (
          <div className="flex flex-col items-start mt-20 w-full max-md:mt-10 max-md:max-w-full">
            {artists.length > 0 && (
              <>
                <h2 className="text-lg font-bold tracking-tight text-center text-white">Artists</h2>
                <div className="flex flex-col mt-6">
                  {artists.map((artist, index) => (
                    <SelectableItem
                      key={`artist-${index}`}
                      name={artist.name}
                      selected={artist.selected}
                      onSelect={() =>
                        setArtists((prev) =>
                          prev.map((a, i) => (i === index ? { ...a, selected: !a.selected } : a))
                        )
                      }
                    />
                  ))}
                </div>
              </>
            )}
  
            {venues.length > 0 && (
              <>
                <h2 className="mt-6 text-lg font-bold tracking-tight text-center text-white">Venues</h2>
                <div className="flex flex-col mt-6">
                  {venues.map((venue, index) => (
                    <SelectableItem
                      key={`venue-${index}`}
                      name={venue.name}
                      location={venue.location}
                      selected={venue.selected}
                      onSelect={() =>
                        setVenues((prev) =>
                          prev.map((v, i) => (i === index ? { ...v, selected: !v.selected } : v))
                        )
                      }
                    />
                  ))}
                </div>
              </>
            )}
  
            <div className="flex flex-col pt-4 mt-6 max-w-full text-base font-medium text-center w-[272px]">
              <button
                className="gap-2 self-center px-6 py-4 text-white rounded-md border border-solid bg-zinc-950 border-zinc-700 max-md:px-5"
                type="button"
                onClick={handleSavePreferences}
              >
                Save notification preferences
              </button>
              <button
                className="gap-2 self-stretch px-6 py-4 mt-4 w-full text-rose-500 rounded-md border border-solid bg-zinc-950 border-zinc-700 max-md:px-5"
                type="button"
                onClick={handleConfirmUnsubscribe}
              >
                Unsubscribe from all
              </button>
            </div>
  
            {message && (
              <div className="mt-6 text-center text-white">
                {message}
              </div>
            )}
  
            {showConfirm && (
              <div className="mt-6 text-center">
                <p className="text-white">Are you sure you want to unsubscribe from all?</p>
                <div className="flex justify-center mt-4 gap-4">
                  <button
                    className="px-4 py-2 bg-red-500 text-white rounded-md"
                    onClick={handleUnsubscribeAll}
                  >
                    Yes
                  </button>
                  <button
                    className="px-4 py-2 bg-gray-600 text-white rounded-md"
                    onClick={handleCancelUnsubscribe}
                  >
                    No
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Notifications;