import React, { useState, useRef } from "react";
import { addNotification } from "../lib/data";

interface NotificationVenueProps {
  venue_id: number; // Accept venue_id as a prop
  venue_name: string; // Accept venue_name as a prop
  closeModal: () => void; // Function to close the modal
}

const NotificationVenue: React.FC<NotificationVenueProps> = ({ venue_id, venue_name, closeModal }) => {
  const [email, setEmail] = useState("");
  const [agree, setAgree] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false); // Track successful submission
  const formRef = useRef<HTMLFormElement>(null); // Reference to the form element

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!email || !agree) {
      setError("Please provide a valid email and agree to the terms.");
      return;
    }

    try {
      await addNotification({
        email, // Maps to `identifier`
        contact_method: "email", // Specifies the contact method
        notification_type: "Venue", // Specifies the type of notification (case-sensitive)
        notification_type_id: venue_id, // Maps to the venue ID
        location_lat: undefined, // Optional: Provide latitude if available
        location_lon: undefined, // Optional: Provide longitude if available
        distance: undefined, // Optional: Provide distance if applicable
      });

      console.log("Notification added successfully");

      // Display success message
      setSuccess(true);

      // Close the modal after 3 seconds
      setTimeout(() => {
        closeModal();
      }, 3000);
    } catch (error) {
      console.error("Failed to submit notification:", error);
      setError("Something went wrong. Please try again later.");
    }
  };

  return (
    <div className="flex overflow-hidden relative flex-col items-center py-8 pr-5 pl-5 rounded-2xl border border-solid bg-zinc-950 border-zinc-700 max-w-[809px] max-md:pl-5">
      {/* Close Button */}
      <img
        loading="lazy"
        src="close-window.png"
        alt="Close"
        className="object-contain absolute top-2 right-2 z-10 w-6 h-6 cursor-pointer"
        onClick={closeModal} // Close the modal on click
      />

      {success ? (
        <div className="text-white text-lg font-medium mt-4 text-center">
          You're signed up to receive notifications from {venue_name}.
        </div>
      ) : (
        <>
          {/* Header */}
          <div className="flex z-0 flex-col w-full font-bold text-center text-white">
            <div className="flex flex-col w-full max-md:max-w-full">
              <div className="text-3xl tracking-tighter leading-none max-md:max-w-full">
                Sign up for new show alerts for
              </div>
              <div className="mt-6 text-5xl tracking-tighter leading-none max-md:max-w-full max-md:text-4xl">
                {venue_name}
              </div>
            </div>
          </div>

          {/* Form */}
          <form ref={formRef} onSubmit={handleSubmit} className="flex z-0 flex-col items-center mt-8 max-w-full w-[560px]">
            <InputField
              label="Email"
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Checkbox
              label="I agree to receive email notifications from myVibe.live"
              id="notifications"
              checked={agree}
              onChange={(e) => setAgree(e.target.checked)}
            />
            {error && <p className="text-red-500 mt-4">{error}</p>}
            <div className="flex gap-4 items-start pt-4 mt-6 text-base font-medium text-center text-white">
              <Button
                onClick={() =>
                  formRef.current?.dispatchEvent(new Event("submit", { bubbles: true }))
                }
              >
                Sign Up
              </Button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

const Button: React.FC<{ children: React.ReactNode; onClick?: () => void }> = ({ children, onClick }) => {
  return (
    <button
      type="button" // Use "button" to avoid interfering with form submission
      onClick={onClick}
      className="gap-2 self-stretch px-6 py-4 my-auto rounded-md border border-solid bg-zinc-950 border-zinc-700 max-md:px-5"
    >
      {children}
    </button>
  );
};

const Checkbox: React.FC<{
  label: string;
  id: string;
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ label, id, checked, onChange }) => {
  return (
    <div className="flex gap-2 items-center mt-6">
      <div className="flex flex-col justify-center self-stretch p-0.5 my-auto w-6">
        <input
          type="checkbox"
          id={id}
          className="flex shrink-0 h-5 rounded-full border border-solid border-zinc-400 stroke-[1px] stroke-zinc-400"
          checked={checked}
          onChange={onChange}
          aria-label={label}
        />
      </div>
      <label htmlFor={id} className="self-stretch my-auto text-sm font-semibold text-center text-white">
        {label}
      </label>
    </div>
  );
};

const InputField: React.FC<{
  label: string;
  id: string;
  type: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ label, id, type, value, onChange }) => {
  return (
    <div className="flex flex-col self-stretch w-full text-xs font-semibold text-center whitespace-nowrap text-neutral-400 max-md:max-w-full">
      <label htmlFor={id} className="self-start">
        {label}
      </label>
      <input
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        className="flex gap-2 mt-2 w-full rounded-md border border-solid bg-zinc-700 border-zinc-700 min-h-[40px] max-md:max-w-full"
        aria-label={label}
      />
    </div>
  );
};

export default NotificationVenue;