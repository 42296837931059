import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { sendContactMessage } from '../lib/data';

const ContactUs: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    // Validate fields
    if (!name || !email || !message) {
      setError('Please fill out all fields.');
      return;
    }

    try {
      // Call the API to send the email
      await sendContactMessage({
        name,
        email,
        phone: "", // No phone number available
        bandname: "", // No artist/band name available
        venuename: "", // No venue name available
        message,
      });
      setSubmitted(true);
    } catch (err) {
      console.error('Failed to send message:', err);
      setError('Something went wrong. Please try again later.');
    }
  };

  if (submitted) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-gray-800 to-black text-white">
        <h1 className="text-3xl font-bold mb-4">Thank You!</h1>
        <p className="text-lg mb-6">Your message has been submitted. We'll get back to you soon.</p>
        <Link
          to="/"
          className="py-3 px-6 bg-blue-400 text-white font-semibold rounded-md hover:bg-blue-500"
        >
          Back to Home
        </Link>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-gray-800 to-black text-white">
      <div className="mb-6">
        <Link to="/">
          <img src="/myvibelogowithtagline.png" alt="Logo" className="w-132 h-32" />
        </Link>
      </div>

      <h1 className="text-4xl font-bold mb-6 text-center">Contact Us</h1>

      <form onSubmit={handleSubmit} className="w-full max-w-md space-y-4">
        {error && <p className="text-red-500">{error}</p>}

        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Your Name"
          className="w-full py-3 px-4 text-gray-700 font-semibold rounded-md"
        />

        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your Email"
          className="w-full py-3 px-4 text-gray-700 font-semibold rounded-md"
        />

        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Your Message"
          rows={4}
          className="w-full py-3 px-4 text-gray-700 font-semibold rounded-md"
        ></textarea>

        <button
          type="submit"
          className="w-full py-3 px-4 bg-blue-400 text-white font-semibold rounded-md hover:bg-blue-500"
        >
          Submit
        </button>
      </form>

      {/* Social Links Section */}
      <div className="mt-6 text-center">
        <p className="text-lg font-semibold mb-4">Or reach out to us on our social channels:</p>
        <div className="flex gap-4 justify-center">
          <a
            href="https://www.facebook.com/myvibelive"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="icon-facebook-color.png"
              alt="Facebook"
              className="w-8 h-8 rounded-full"
            />
          </a>
          <a
            href="https://www.instagram.com/myvibe.live/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="icon-instagram-color.jpg"
              alt="Instagram"
              className="w-8 h-8 rounded-full"
            />
          </a>
          <a
            href="https://www.tiktok.com/@myvibe.live"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="icon-tiktok-color.png"
              alt="TikTok"
              className="w-8 h-8 rounded-full"
            />
          </a>
        </div>
      </div>

      <p className="mt-6 text-sm text-gray-400">
        By reaching out, you agree to our <a href="/termsofservice" className="underline">Terms of Service</a>.
      </p>
    </div>
  );
};

export default ContactUs;