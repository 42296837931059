import React, { useEffect, useState, useRef } from "react";
import { addNotification } from "../lib/data";

interface NotificationArtistProps {
  artist_id: number;
  artist_name: string;
  closeModal: () => void;
}

const NotificationArtist: React.FC<NotificationArtistProps> = ({
  artist_id,
  artist_name,
  closeModal,
}) => {
  const [email, setEmail] = useState("");
  const [distance, setDistance] = useState("10");
  const [cityState, setCityState] = useState<string>("Determining location...");
  const [agree, setAgree] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [userLat, setUserLat] = useState<string | null>(null);
  const [userLon, setUserLon] = useState<string | null>(null);
  const [locationInput, setLocationInput] = useState<string>("");
  const [locationError, setLocationError] = useState<string | null>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const getBrowserLocation = async () => {
    return new Promise<{ lat: number; lon: number }>((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            });
          },
          (error) => {
            reject(new Error("Failed to retrieve browser location: " + error.message));
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  const getIpBasedLocation = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const { ip } = await response.json();
      const apiKey = process.env.REACT_APP_MAXMIND_KEY;
      const ipResponse = await fetch(
        `https://geoip.maxmind.com/geoip/v2.1/city/${ip}?key=${apiKey}`
      );
      if (!ipResponse.ok) {
        throw new Error("Failed to retrieve IP-based location");
      }
      const locationData = await ipResponse.json();
      return {
        lat: locationData.location.latitude,
        lon: locationData.location.longitude,
      };
    } catch (error) {
      console.error("IP-based Geolocation Error:", error);
      throw error;
    }
  };

  const getLocationFromGoogle = async () => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY;
      const response = await fetch(
        `https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`,
        { method: "POST" }
      );

      if (!response.ok) {
        throw new Error("Failed to retrieve location from Google API");
      }

      const data = await response.json();
      if (data.location) {
        return {
          lat: data.location.lat,
          lon: data.location.lng,
        };
      } else {
        throw new Error("Google Geolocation API did not return location");
      }
    } catch (error) {
      console.error("Google Geolocation API Error:", error);
      throw error;
    }
  };

  const getLocation = async () => {
    try {
      const browserLocation = await getBrowserLocation();
      setUserLat(browserLocation.lat.toString());
      setUserLon(browserLocation.lon.toString());
      await getCityState(browserLocation.lat.toString(), browserLocation.lon.toString());
      return;
    } catch (error) {
      console.error("Browser location failed:", error);
    }

    try {
      const googleLocation = await getLocationFromGoogle();
      setUserLat(googleLocation.lat.toString());
      setUserLon(googleLocation.lon.toString());
      await getCityState(googleLocation.lat.toString(), googleLocation.lon.toString());
      return;
    } catch (error) {
      console.error("Google location failed:", error);
    }

    try {
      const ipLocation = await getIpBasedLocation();
      setUserLat(ipLocation.lat.toString());
      setUserLon(ipLocation.lon.toString());
      await getCityState(ipLocation.lat.toString(), ipLocation.lon.toString());
    } catch (error) {
      console.error("IP-based location failed:", error);
      setCityState("Unable to determine location");
    }
  };

  const getCityState = async (lat: string, lon: string) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`
      );

      if (!response.ok) {
        throw new Error("Failed to reverse geocode coordinates");
      }

      const data = await response.json();
      if (data.status === "OK") {
        const addressComponents = data.results[0]?.address_components || [];
        const city = addressComponents.find((comp: any) =>
          comp.types.includes("locality")
        )?.long_name;
        const state = addressComponents.find((comp: any) =>
          comp.types.includes("administrative_area_level_1")
        )?.short_name;

        if (city && state) {
          setCityState(`${city}, ${state}`);
        } else {
          setCityState("Location not found");
        }
      } else {
        setCityState("Location not found");
      }
    } catch (error) {
      console.error("Error retrieving city and state:", error);
      setCityState("Error retrieving location");
    }
  };

  const handleLocationSubmit = async () => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          locationInput
        )}&key=${apiKey}`
      );

      if (!response.ok) {
        throw new Error("Unable to find the location. Please try again.");
      }

      const data = await response.json();
      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        setUserLat(location.lat.toString());
        setUserLon(location.lng.toString());
        setLocationError(null);
        await getCityState(location.lat.toString(), location.lng.toString());
      } else {
        setLocationError("Unable to find the location. Please try again.");
      }
    } catch (error) {
      setLocationError("An error occurred while fetching the location.");
      console.error(error);
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!email || !agree) {
      setError("Please provide a valid email and agree to the terms.");
      return;
    }

    try {
      await addNotification({
        email,
        contact_method: "email",
        notification_type: "artist",
        notification_type_id: artist_id,
        location_lat: userLat || undefined,
        location_lon: userLon || undefined,
        distance: parseInt(distance),
      });

      setSuccess(true);
      setTimeout(closeModal, 3000);
    } catch (error) {
      console.error("Failed to submit notification:", error);
      setError("Something went wrong. Please try again later.");
    }
  };

  return (
    <div className="flex overflow-hidden relative flex-col items-center py-8 pr-5 pl-5 rounded-2xl border border-solid bg-zinc-950 border-zinc-700 max-w-[809px] max-md:pl-5">
      <img
        loading="lazy"
        src="close-window.png"
        alt="Close"
        className="object-contain absolute top-2 right-2 z-10 w-6 h-6 cursor-pointer"
        onClick={closeModal}
      />
      {success ? (
        <div className="text-white text-lg font-medium mt-4 text-center">
          You're signed up to receive notifications from {artist_name}.
        </div>
      ) : (
        <>
          <div className="flex z-0 flex-col w-full font-bold text-center text-white">
            <div className="text-3xl">Sign up for new show alerts for</div>
            <div className="mt-6 text-5xl">{artist_name}</div>
          </div>
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            className="flex z-0 flex-col items-center mt-8 max-w-full w-[560px]"
          >
            <div className="flex flex-col w-full gap-4">
            {/* Distance Selection */}
            <div className="flex w-full gap-4 items-center">
                <div className="flex flex-col w-1/4">
                <label
                    htmlFor="distance"
                    className="flex flex-col self-stretch w-full text-xs font-semibold whitespace-nowrap text-neutral-400 max-md:max-w-full"
                >
                    Within
                </label>
                <select
                    id="distance"
                    value={distance}
                    onChange={(e) => setDistance(e.target.value)}
                    className="px-2 py-2 rounded-md bg-zinc-700 text-white focus:ring-2 focus:ring-zinc-500"
                >
                    <option value="10">10 mi</option>
                    <option value="25">25 mi</option>
                    <option value="50">50 mi</option>
                    <option value="100">100 mi</option>
                </select>
                </div>
                {/* City, State */}
                <div className="flex flex-col flex-grow">
                <label
                    htmlFor="cityState"
                    className="flex flex-col self-stretch w-full text-xs font-semibold whitespace-nowrap text-neutral-400 max-md:max-w-full"
                >
                    Location
                </label>
                <input
                    id="cityState"
                    type="text"
                    value={cityState}
                    readOnly
                    className="px-2 py-2 rounded-md bg-zinc-700 text-white focus:ring-2 focus:ring-zinc-500"
                />
                </div>
            </div>

            {/* Set Location Button */}
            <div className="flex justify-center w-full mt-2">
                <button
                type="button"
                onClick={() => setLocationInput(" ")} // Open modal without clearing locationInput
                className="px-4 py-2 rounded-md bg-zinc-950 text-white border border-zinc-700"
                >
                Set New Location
                </button>
            </div>
            </div>
            {/* Email Input */}
            <div className="flex flex-col w-full mt-4">
              <label
                htmlFor="email"
                className="lex flex-col self-stretch w-full text-xs font-semibold whitespace-nowrap text-neutral-400 max-md:max-w-full"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="px-4 py-2 rounded-md bg-zinc-700 text-white border-none"
              />
            </div>
            {/* Agreement Checkbox */}
            <div className="flex items-center mt-4">
              <input
                id="agree"
                type="checkbox"
                checked={agree}
                onChange={(e) => setAgree(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="agree" className="text-sm text-white">
                I agree to receive email notifications from myVibe.live
              </label>
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}
            {/* Submit Button */}
            <button
              type="submit"
              className="mt-6 px-6 py-4 rounded-md bg-zinc-950 text-white border border-zinc-700"
            >
              Sign Up
            </button>
          </form>

        {/* Location Input Modal */}
        {locationInput !== "" && (
        <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
            style={{ backdropFilter: "blur(5px)" }}
        >
            <div className="bg-zinc-950 p-6 rounded-lg w-[400px] shadow-lg">
            <h2 className="text-xl font-bold mb-4 text-white">Set a New Location</h2>
            <input
                type="text"
                value={locationInput}
                onChange={(e) => setLocationInput(e.target.value)}
                placeholder="Enter city, state"
                className="w-full px-4 py-2 mb-4 rounded-md bg-zinc-700 text-white border-none"
            />
            {locationError && <p className="text-red-500 mb-4">{locationError}</p>}
            <div className="flex justify-end gap-4">
                <button
                onClick={async () => {
                    await handleLocationSubmit(); // Ensure location is fetched
                    if (!locationError) {
                    setLocationInput(""); // Close the modal only if no error occurs
                    }
                }}
                className="px-4 py-2 rounded-md bg-zinc-950 text-white border border-zinc-700"
                >
                Save
                </button>
                <button
                onClick={() => setLocationInput("")} // Close modal without making changes
                className="px-4 py-2 rounded-md bg-zinc-950 text-white border border-zinc-700"
                >
                Cancel
                </button>
            </div>
            </div>
        </div>
        )}
        </>
      )}
    </div>
  );
};

export default NotificationArtist;