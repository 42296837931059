import React, { useState } from "react";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import { sendContactMessage } from '../lib/data';

const WorkWithUsSection: React.FC = () => {
  // Create references for the artist and venue sections
  const artistSectionRef = React.useRef<HTMLDivElement>(null);
  const venueSectionRef = React.useRef<HTMLDivElement>(null);

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <MainHeader />

      {/* Hero Section */}
      <section
        className="relative flex flex-col gap-10 items-start px-16 min-h-[450px] max-md:px-5 overflow-hidden"
        role="region"
        aria-label="Work with us section"
      >
        <img
          loading="lazy"
          src="work-with-us-header.png"
          alt="Work With Us"
          className="absolute inset-0 z-0 w-full h-full object-cover"
        />
        <div className="relative z-10 flex flex-col my-auto min-w-[240px] w-[560px] max-md:max-w-full">
          <h1 className="text-6xl font-bold text-white tracking-tighter leading-none max-md:text-4xl"
          style={{ fontFamily: 'League Spartan, sans-serif' }}>
            Work with us
          </h1>
          <p className="mt-6 text-lg text-white leading-loose max-md:max-w-full">
            Get listed in our directory of artists and venues
          </p>
          <div className="flex gap-4 mt-6">
            <button
              onClick={() => scrollToSection(artistSectionRef)}
              className="px-6 py-4 rounded-md border bg-zinc-950 border-zinc-700 text-white font-medium hover:bg-zinc-800 focus:ring-2 focus:ring-white transition-colors"
              aria-label="Navigate to artist signup page"
            >
              Sign up as an Artist
            </button>
            <button
              onClick={() => scrollToSection(venueSectionRef)}
              className="px-6 py-4 rounded-md border bg-zinc-950 border-zinc-700 text-white font-medium hover:bg-zinc-800 focus:ring-2 focus:ring-white transition-colors"
              aria-label="Navigate to venue signup page"
            >
              Sign up as a Venue
            </button>
          </div>
        </div>
      </section>

      {/* Venue Contact Section */}
      <div
        ref={venueSectionRef}
        className="min-h-screen bg-zinc-950 text-white flex justify-center items-center"
      >
        <div className="max-w-7xl w-full flex flex-wrap gap-10 px-8 md:px-16">
          <div className="flex-1 min-w-[240px]">
            <h2 className="text-4xl font-bold leading-tight"
            style={{ fontFamily: 'League Spartan, sans-serif' }}>
              Are you a venue that hosts live music?
            </h2>
            <p className="mt-6 text-lg leading-relaxed text-zinc-400">
              myVibe.live is a comprehensive platform designed to amplify your
              venue's presence and connect you with local music fans. With tools to
              easily promote your events, highlight upcoming shows, and feature
              artist lineups, we help you reach a broader audience while
              streamlining your event management. Our platform ensures your venue
              stands out with customized profiles, analytics to track engagement,
              and seamless integration with social media. myVibe.live makes it easy
              to keep your calendar full and your audience engaged.
            </p>
          </div>
          <ContactForm isArtist={false} />
        </div>
      </div>

      {/* Artist Contact Section */}
      <div
        ref={artistSectionRef}
        className="min-h-screen bg-zinc-950 text-white flex justify-center items-center"
      >
        <div className="max-w-7xl w-full flex flex-wrap gap-10 px-8 md:px-16">
          <div className="flex-1 min-w-[240px]">
            <h2 className="text-4xl font-bold leading-tight"
            style={{ fontFamily: 'League Spartan, sans-serif' }}>
              Are you an artist looking to promote themselves?
            </h2>
            <p className="mt-6 text-lg leading-relaxed text-neutral-400">
              myVibe.live is the ultimate platform for artists to showcase their
              talent, connect with fans, and grow their audience. Create a
              personalized profile to highlight your music, upcoming shows, and
              social links, ensuring your fans always know where to find you. Gain
              valuable insights with performance analytics and make your mark in
              the live music scene. Whether you’re a solo performer or part of a
              band, myVibe.live is your stage to shine.
            </p>
          </div>
          <ContactForm isArtist={true} />
        </div>
      </div>

      <Footer />
    </>
  );
};

const ContactForm: React.FC<{ isArtist: boolean }> = ({ isArtist }) => {
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      phone: "",
      message: "",
      venueName: "",
      bandName: "",
    });
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState<string | null>(null);
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      setError(null);
  
      // Validate required fields
      if (!formData.name || !formData.email || !formData.message) {
        setError("Please fill out all required fields.");
        return;
      }
  
      try {
        // Call the API to send the email
        await sendContactMessage({
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          bandname: formData.bandName,
          venuename: formData.venueName,
          message: formData.message,
        });
        setSubmitted(true);
      } catch (err) {
        console.error("Failed to submit form:", err);
        setError("Something went wrong. Please try again later.");
      }
    };
  
    if (submitted) {
      return (
        <div className="text-center">
          <h2 className="text-2xl font-bold mb-4">Thank you!</h2>
          <p className="text-lg">Your message has been submitted. We'll get back to you soon.</p>
        </div>
      );
    }
  
    return (
      <form
        onSubmit={handleSubmit}
        className="flex-1 flex flex-col gap-4"
        aria-label={isArtist ? "Artist Contact Form" : "Venue Contact Form"}
      >
        {error && <p className="text-red-500">{error}</p>}
  
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Name"
          className="w-full px-4 py-2 rounded-md bg-neutral-900 text-neutral-200 border border-zinc-700"
          required
        />
  
        {isArtist && (
          <input
            type="text"
            name="bandName"
            value={formData.bandName}
            onChange={handleChange}
            placeholder="Band Name"
            className="w-full px-4 py-2 rounded-md bg-neutral-900 text-neutral-200 border border-zinc-700"
          />
        )}
  
        {!isArtist && (
          <input
            type="text"
            name="venueName"
            value={formData.venueName}
            onChange={handleChange}
            placeholder="Venue Name"
            className="w-full px-4 py-2 rounded-md bg-neutral-900 text-neutral-200 border border-zinc-700"
          />
        )}
  
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          className="w-full px-4 py-2 rounded-md bg-neutral-900 text-neutral-200 border border-zinc-700"
          required
        />
  
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Phone Number"
          className="w-full px-4 py-2 rounded-md bg-neutral-900 text-neutral-200 border border-zinc-700"
        />
  
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Message"
          rows={4}
          className="w-full px-4 py-2 rounded-md bg-neutral-900 text-neutral-200 border border-zinc-700"
          required
        ></textarea>
  
        <div className="flex justify-center">
          <button
            type="submit"
            className="gap-2 self-stretch px-6 py-4 my-auto rounded-md border border-solid bg-zinc-950 border-zinc-700 max-md:px-5"
          >
            Submit
          </button>
        </div>
      </form>
    );
  };

export default WorkWithUsSection;