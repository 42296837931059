import React from "react";

const HomePage: React.FC = () => {
  return (
    <div className="bg-black text-white font-sans">
      {/* Header */}
      <header className="flex items-center justify-between px-8 py-4">
        <div className="text-lg font-bold">MYVIBELIVE</div>
        <nav className="flex space-x-6">
          <a href="#" className="hover:underline">
            About Us
          </a>
          <a href="#" className="hover:underline">
            Show Search
          </a>
          <a href="#" className="hover:underline">
            Work With Us
          </a>
          <a href="#" className="hover:underline">
            Venue and Artist Directory
          </a>
        </nav>
      </header>

      {/* Hero Section */}
      <section className="text-center py-16 px-6">
        <h1 className="text-5xl font-extrabold mb-4">Find Local Music To Fit Your Vibe</h1>
        <button className="mt-4 px-6 py-2 bg-gray-800 text-white rounded-full hover:bg-gray-700">
          Contact Us
        </button>
      </section>

      {/* Subtext */}
      <section className="text-center px-6 py-8 bg-gray-900">
        <p className="text-lg">
          Love live music? MyVibeLive makes it easy to find concerts, gigs, and festivals happening in the
          Green Bay & Fox Valley area. With geo-targeted search, you'll never miss a show—whether it's your
          favorite band or an exciting new artist playing at a local venue.
        </p>
      </section>

      {/* Search Section */}
      <section className="py-16 px-6">
        <h2 className="text-3xl font-bold text-center mb-8">Ready to Find Your Vibe?</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-4xl mx-auto">
          {/* Location */}
          <div>
            <label htmlFor="location" className="block text-sm font-medium mb-2">
              Location
            </label>
            <input
              type="text"
              id="location"
              placeholder="Search Location"
              className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg focus:ring-2 focus:ring-indigo-500 focus:outline-none"
            />
          </div>
          {/* Dates */}
          <div>
            <label htmlFor="dates" className="block text-sm font-medium mb-2">
              Dates
            </label>
            <input
              type="text"
              id="dates"
              placeholder="Search Dates"
              className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg focus:ring-2 focus:ring-indigo-500 focus:outline-none"
            />
          </div>
          {/* Vibe */}
          <div>
            <label htmlFor="vibe" className="block text-sm font-medium mb-2">
              Vibe
            </label>
            <input
              type="text"
              id="vibe"
              placeholder="Search Vibe"
              className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg focus:ring-2 focus:ring-indigo-500 focus:outline-none"
            />
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-center py-4">
        <p className="text-sm">&copy; 2024 MyVibeLive. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HomePage;