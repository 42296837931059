import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchArtists, fetchVenues } from "../lib/data";
import { Venue, Artist } from "../lib/definitions";

const AdminList: React.FC = () => {
  const [venues, setVenues] = useState<Venue[]>([]);
  const [artists, setArtists] = useState<Artist[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const venuesData = await fetchVenues();
        const artistsData = await fetchArtists();

        setVenues(venuesData);
        setArtists(artistsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const groupVenuesByLocation = (venues: Venue[]) => {
    return venues.reduce((acc, venue) => {
      const key = `${venue.addr_city}, ${venue.addr_state}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(venue);
      return acc;
    }, {} as Record<string, Venue[]>);
  };

  if (loading) {
    return <div className="min-h-screen flex items-center justify-center">Loading...</div>;
  }

  const groupedVenues = groupVenuesByLocation(venues);

  return (
    <div className="min-h-screen flex flex-row text-gray-800">
      {/* Venues Section */}
      <div className="w-1/2 border-r border-gray-300 p-4">
        <h2 className="text-2xl font-bold mb-4">Venues</h2>
        {Object.entries(groupedVenues).map(([location, venues]) => (
          <div key={location} className="mb-6">
            <h3 className="text-lg font-semibold">{location}</h3>
            <ul className="ml-4">
              {venues.map((venue) => (
                <li key={venue.venue_id} className="mb-2">
                  <a
                    href={`/venue?venuekey=${venue.venue_key}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    {venue.name}
                  </a>
                  <p className="text-sm text-gray-600">Access Token: {venue.access_token}</p>
                  <p className="text-sm text-gray-600">Sub Level: {venue.sub_level}</p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Artists Section */}
      <div className="w-1/2 p-4">
        <h2 className="text-2xl font-bold mb-4">Artists</h2>
        {artists.map((artist) => (
          <div key={artist.artist_id} className="mb-4">
            <h3 className="text-lg font-semibold">
              {Array.isArray(artist.genres) && artist.genres.length > 0 ? (
                artist.genres.map((genre, index) => (
                  <span key={index} className="mr-2 bg-gray-200 px-2 py-1 rounded text-sm text-gray-800">
                    {genre.description}
                  </span>
                ))
              ) : (
                <span className="text-gray-500">No genres available</span>
              )}
            </h3>
            <ul className="ml-4">
              <li>
                <a
                  href={`/artist?artistkey=${artist.artist_key}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  {artist.name}
                </a>
                <p className="text-sm text-gray-600">Access Token: {artist.access_token}</p>
                <p className="text-sm text-gray-600">Sub Level: {artist.sub_level}</p>
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminList;