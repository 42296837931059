import React from "react";

// Interfaces for props
interface SocialIconProps {
  src: string;
  alt: string;
}

interface NavigationLinkProps {
  text: string;
}

interface FooterLinkProps {
  text: string;
}

// SocialIcon Component
const SocialIcon: React.FC<SocialIconProps> = ({ src, alt }) => (
  <img
    loading="lazy"
    src={src}
    alt={alt}
    className="object-contain shrink-0 self-stretch w-8 h-8"
  />
);

// NavigationLink Component
const NavigationLink: React.FC<NavigationLinkProps> = ({ text }) => (
  <div className="text-sm font-medium text-center text-white">{text}</div>
);

// FooterLink Component
const FooterLink: React.FC<FooterLinkProps> = ({ text }) => (
  <div className="text-sm font-semibold text-center text-white">{text}</div>
);

// Data for navigation links, social icons, and footer links
const navigationLinks = [
  "Venue & Artist Directory",
  "Show Search",
  "About Us",
  "Work with us",
  "Artist / Venue Login",
];

const socialIcons = [
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/08bd06543e88a558b4def9e20bd28f9b5752ef6fc2e633ef05973be78e4799eb?placeholderIfAbsent=true&apiKey=cf806ce5720643fabdc4e52055dcf02a",
    alt: "Social Media Icon 1",
  },
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/8bb9f12861f2133acf2c6e9c8ea03fb36a25aa2839a4029a0a6e63efc307ab20?placeholderIfAbsent=true&apiKey=cf806ce5720643fabdc4e52055dcf02a",
    alt: "Social Media Icon 2",
  },
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/f3f56f6f00e29dc8a48d8d1274912545af0bfb8d20631a284e206a4cd0d1d54e?placeholderIfAbsent=true&apiKey=cf806ce5720643fabdc4e52055dcf02a",
    alt: "Social Media Icon 3",
  },
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/855d889d6bf16cab80e682a7edc8274051d672e7c9ae49420b40ecc619e00fdb?placeholderIfAbsent=true&apiKey=cf806ce5720643fabdc4e52055dcf02a",
    alt: "Social Media Icon 4",
  },
  {
    src: "https://cdn.builder.io/api/v1/image/assets/TEMP/bc4321d7c5ecf4843578c30331960ab011c666b899cbc8ec33d995ab05bf0bc0?placeholderIfAbsent=true&apiKey=cf806ce5720643fabdc4e52055dcf02a",
    alt: "Social Media Icon 5",
  },
];

// Footer Component
const Footer: React.FC = () => {
  return (
    <div
      className="w-full flex flex-col items-center px-4 py-0 bg-zinc-950 md:px-16"
      style={{
        background: "linear-gradient(180deg, #0a0a0b 0%, #1a0d2e 30%, #321752 70%, #3A1D5D 100%)",
        marginTop: "0",
        paddingTop: "0",
      }}
    >
      <div className="flex flex-wrap items-center justify-between w-full px-8 py-4 md:flex-row flex-col">
        {/* Logo */}
        <div className="mb-4 md:mb-0">
          <img
            loading="lazy"
            src="myvibelive logo.png"
            alt="Company Logo"
            className="w-[100px] object-contain"
          />
        </div>

        {/* Links */}
        <div className="flex flex-wrap items-center gap-6 mb-4 md:mb-0 md:flex-row flex-col">
          <a href="/search">
            <div className="text-sm font-medium leading-6 text-white">Show Search</div>
          </a>
          <a href="/">
            <div className="text-sm font-medium leading-6 text-white">About Us</div>
          </a>
          <a href="/workwithus">
            <div className="text-sm font-medium leading-6 text-white">Work with us</div>
          </a>
          <a href="/login">
            <div className="text-sm font-medium leading-6 text-white">Artist / Venue Login</div>
          </a>
        </div>

        {/* Social Media Icons */}
        <div className="flex items-center space-x-6 md:space-x-6 md:mt-0 mt-4">
          <a href="https://www.facebook.com/myvibelive" target="_blank" rel="noopener noreferrer">
            <img src="icon-facebook.svg" alt="Facebook Icon" className="w-6 h-6" />
          </a>
          <a href="https://www.instagram.com/myvibe.live" target="_blank" rel="noopener noreferrer">
            <img src="icon-instagram.svg" alt="Instagram Icon" className="w-6 h-6" />
          </a>
          <a href="https://www.tiktok.com/@myvibe.live" target="_blank" rel="noopener noreferrer">
            <img src="icon-tiktok.svg" alt="TikTok Icon" className="w-6 h-6" />
          </a>
        </div>
      </div>

      {/* Divider */}
      <div className="w-full h-[1px] bg-gray-500 mb-6"></div>

      {/* Footer Links */}
      <div className="text-center text-sm font-semibold text-white">
        2025 myVibe.live. All rights reserved.
      </div>
      <div className="flex flex-col items-center gap-2 mt-4 md:flex-row md:gap-6">
        <a href="/notifications" className="text-sm font-semibold text-center text-white">
          Manage Notifications
        </a>
        <a href="/privacypolicy" target="_blank" rel="noopener noreferrer" className="text-sm font-semibold text-center text-white">
          Privacy Policy
        </a>
        <a href="/termsofservice" target="_blank" rel="noopener noreferrer" className="text-sm font-semibold text-center text-white">
          Terms of Service
        </a>
      </div>
    </div>
  );
};

export default Footer;