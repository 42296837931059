import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";

const Login = ({ defaultAccessToken = "", defaultMessage = "" }) => {
  const location = useLocation();
  const [accessToken, setAccessToken] = useState(defaultAccessToken);
  const [message, setMessage] = useState(defaultMessage);
  const { loginWithRedirect, logout, getAccessTokenSilently, error } = useAuth0();

  // Fetch access_token and message from the URL query string
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenFromUrl = queryParams.get("access_token");
    const messageFromUrl = queryParams.get("message");

    if (tokenFromUrl) {
      setAccessToken(tokenFromUrl); // Store access_token from the URL
    }

    if (messageFromUrl) {
      setMessage(decodeURIComponent(messageFromUrl));
    }
  }, [location]);

  // Handle OAuth login with the access_token
  const handleOAuthLogin = (provider) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    window.location.href = `${apiBaseUrl}/api/auth/${provider}?access_token=${accessToken}`;
  };

  return (
    <div className="min-h-screen flex flex-col">
      <MainHeader />
      <div className="flex flex-col items-center px-6 py-16 bg-zinc-950 min-h-screen text-white">
        {/* Logo */}
        <div className="mb-10">
          <img
            loading="lazy"
            src="logo.png"
            alt="MyVibe Live Logo"
            className="w-[150px] mx-auto"
          />
        </div>

        {/* Header */}
        <h1 className="text-4xl md:text-6xl font-bold text-center mb-8"
        style={{ fontFamily: 'League Spartan, sans-serif' }}>
          Manage Your Profile
        </h1>

        {/* Input Section */}
        <div className="w-full max-w-md mx-auto">
          <label
            htmlFor="accessCode"
            className="block text-sm font-semibold text-neutral-400 mb-2"
          >
            Access Code
          </label>
          <input
            id="accessCode"
            type="text"
            value={accessToken}
            onChange={(e) => setAccessToken(e.target.value)}
            className="flex gap-2 mt-2 w-full rounded-md border border-solid bg-zinc-700 border-zinc-700 min-h-[40px]"
            aria-label="Enter access code to connect your account"
          />
          <div className="flex justify-center">
            <button
              onClick={() => handleOAuthLogin("auth0")}
              className="px-6 py-4 mt-6 text-base font-medium text-white text-center rounded-md border border-solid bg-zinc-950 border-zinc-700"
            >
              Login
            </button>
          </div>
        </div>

        {/* Help Section */}
        <div className="text-center mt-8 text-sm font-semibold text-neutral-400">
          Need an access code? Visit our <a href="/workwithus" className="text-purple-500 underline hover:text-purple-400">
            Work With Us 
          </a> page or message us on our social sites to get one!
        </div>

        {/* Social Media Links */}
        <div className="flex justify-center gap-6 mt-6">
          <a
            href="https://www.facebook.com/myvibelive"
            target="_blank"
            rel="noopener noreferrer"
            className="w-10 h-10 rounded-full overflow-hidden flex items-center justify-center bg-neutral-800 hover:bg-neutral-700"
          >
            <img src="icon-facebook.png" alt="Facebook" className="w-6 h-6" />
          </a>
          <a
            href="https://www.instagram.com/myvibe.live"
            target="_blank"
            rel="noopener noreferrer"
            className="w-10 h-10 rounded-full overflow-hidden flex items-center justify-center bg-neutral-800 hover:bg-neutral-700"
          >
            <img src="icon-instagram.png" alt="Instagram" className="w-6 h-6" />
          </a>
          <a
            href="https://www.tiktok.com/@myvibe.live"
            target="_blank"
            rel="noopener noreferrer"
            className="w-10 h-10 rounded-full overflow-hidden flex items-center justify-center bg-neutral-800 hover:bg-neutral-700"
          >
            <img src="icon-tiktok.png" alt="TikTok" className="w-6 h-6" />
          </a>
        </div>

        {/* Terms of Service */}
        <div className="text-center mt-8 text-sm">
          By continuing, you accept our{" "}
          <a
            href="/termsofservice"
            className="text-purple-500 underline hover:text-purple-400"
          >
            Terms of Service
          </a>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;