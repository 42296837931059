'use client';

import { useState, useEffect } from 'react';
import SearchBar from '../components/SearchBar';
import ArtistInfo from '../components/ArtistInfo';
import ShowCard from '../components/ShowCard';
import Footer from '../components/Footer';
import MainHeader from '../components/MainHeader';
import NotificationArtist from '../components/NotificationArtist';
import { useQuery } from '@tanstack/react-query';
import { fetchArtist, fetchShows, logEvent } from '../lib/data';
import type { Artist, Show, Venue, Genre } from '../lib/definitions';

function ArtistPage() {
  const [activeTab, setActiveTab] = useState<'upcoming' | 'past'>('upcoming'); // Manage tab state
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false); // Modal state

  const searchParams = new URLSearchParams(window.location.search);
  const artistKey = searchParams.get('artistkey');

  // Fetch artist details
  const { data: artist, error: artistError, isLoading: artistLoading } = useQuery<Artist, Error>({
    queryKey: ['artist', artistKey],
    queryFn: () => fetchArtist(artistKey!),
    enabled: !!artistKey,
  });

  // Fetch shows (upcoming or past based on activeTab)
  const { data: shows, error: showsError, isLoading: showsLoading } = useQuery<Show[], Error>({
    queryKey: ['shows', artistKey, activeTab],
    queryFn: () =>
      fetchShows(
        null,
        artistKey,
        null,
        null,
        null,
        activeTab === 'past' ? 'Past' : 'Future'
      ),
    enabled: !!artistKey,
  });

  // Log visit when artist data is loaded
  useEffect(() => {
    if (artist?.artist_id) {
      logEvent(null, 'Visit', artist.artist_id, 'Artist');
    }
  }, [artist]);

  // Modal handlers
  const openNotificationModal = () => setIsNotificationModalOpen(true);
  const closeNotificationModal = () => setIsNotificationModalOpen(false);

  // Handle loading and error states
  if (artistLoading || showsLoading) {
    return (
      <>
        <div className="flex flex-col items-center justify-center min-h-screen">
          <img
            src="logo-animate.gif"
            alt="Loading..."
            className="max-w-[20rem] md:max-w-[25rem] lg:max-w-[30rem] object-contain"
          />
        </div>
        <Footer />
      </>
    );
  }

  if (artistError || showsError) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <p className="text-lg text-zinc-700 font-medium">Error loading data. Please try again later.</p>
        <Footer />
      </div>
    );
  }

  return (
    <>
      <MainHeader />
      <div className="flex flex-col items-center mx-auto w-full bg-neutral-200">
        <header className="flex flex-col px-4 pt-2 pb-4 w-full text-center bg-zinc-950">
          <SearchBar />
        </header>

        <div className="flex flex-col w-full max-w-[750px] mx-auto bg-neutral-200">
          {artist && <ArtistInfo artist={artist} />}

          <section className="flex flex-col w-full px-4 pt-4 mt-4">
            <nav className="flex gap-6 justify-center items-start mt-6 w-full text-sm font-medium leading-6 text-center text-zinc-950">
              <button
                className={`flex-1 px-4 py-2 ${
                  activeTab === 'upcoming' ? 'border-b-2 border-solid border-b-zinc-950' : ''
                }`}
                onClick={() => setActiveTab('upcoming')}
              >
                Upcoming Shows
              </button>
              <button
                className={`flex-1 px-4 py-2 ${
                  activeTab === 'past' ? 'border-b-2 border-solid border-b-zinc-950' : ''
                }`}
                onClick={() => setActiveTab('past')}
              >
                Past Shows
              </button>
            </nav>

            {shows?.length === 0 ? (
              <p className="text-center text-lg text-zinc-700 font-medium mt-4 mb-4">
                Currently, there are no scheduled shows
              </p>
            ) : (
              <div className="flex flex-col pt-8 w-full max-w-[750px]">
                {shows?.map((show) => {
                  const venues: Venue[] = Array.isArray(show.venues) ? show.venues : [];
                  const genres: Genre[] = Array.isArray(show.genres) ? show.genres : [];
                  const artists: Artist[] = Array.isArray(show.artists) ? show.artists : [];

                  return (
                    <ShowCard
                      key={show.show_id}
                      name={show.name || ''}
                      date={show.date_start || ''}
                      time={show.time_start || ''}
                      show={show}
                      venue={venues}
                      genres={genres}
                      artists={artists}
                    />
                  );
                })}
              </div>
            )}
          </section>
        </div>
        <Footer />
      </div>

      {/* Notification Modal */}
      {isNotificationModalOpen && (
        <NotificationArtist
          artist_id={artist?.artist_id || 0}
          artist_name={artist?.name || 'Artist'}
          closeModal={closeNotificationModal}
        />
      )}
    </>
  );
}

export default ArtistPage;