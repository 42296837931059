import React from "react";
import { Artist } from "../lib/definitions";

interface ArtistLineupProps {
  artists: Artist[];
  show_image_thumb?: string;
  venue_image_thumb?: string;
}

const ArtistLineup: React.FC<ArtistLineupProps> = ({ artists, show_image_thumb, venue_image_thumb }) => {
  return (
    <div className="flex flex-col px-4 py-6 w-full">
      <div className="flex gap-4 items-center w-full">
      <h3
        className="flex-1 shrink self-stretch my-auto text-lg font-bold tracking-tight text-black basis-0"
        style={{ fontFamily: 'League Spartan, sans-serif' }}
      >
        Artist Lineup
      </h3>
      </div>
      <div className="flex flex-wrap gap-2 items-start mt-4 w-full text-xs font-semibold text-center text-zinc-950 overflow-x-auto">
        {artists.map((artist, index) => {
          let imageSrc = "default_thumb.webp"; // Default image
          
          // Check in order: artist.image_thumb -> show_image_thumb -> venue_image_thumb -> default
          if (artist.image_thumb && artist.sub_level !== 'Opener') {  // Use !== for inequality
            imageSrc = artist.image_thumb;
          } else if (show_image_thumb) {
            imageSrc = show_image_thumb;
          } else if (venue_image_thumb) {
            imageSrc = venue_image_thumb;
          } else {
            imageSrc = 'default_thumb.webp'; // Provide a default fallback if none of the above conditions are met
          }
          return (
            <a key={index} href={`artist?artistkey=${artist.artist_key}`}>
              <div className="flex flex-col">
                <img
                  loading="lazy"
                  src={imageSrc}  // Use the imageSrc variable
                  alt={`${artist.name} profile`}
                  className="object-contain self-center rounded-[50%] aspect-[1] w-[80px]"
                />
                <div className="mt-2 w-[80px]">{artist.name}</div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default ArtistLineup;