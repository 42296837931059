import React, { useEffect, useState } from "react";

interface NoShowsProps {
  userLat: string | null;
  userLon: string | null;
  setUserLat: React.Dispatch<React.SetStateAction<string | null>>; // Allow null
  setUserLon: React.Dispatch<React.SetStateAction<string | null>>; // Allow null
}

const NoShows: React.FC<NoShowsProps> = ({ userLat, userLon, setUserLat, setUserLon }) => {
  const [cityState, setCityState] = useState<string>("Checking your location...");
  const [loading, setLoading] = useState<boolean>(true);
  const [locationInput, setLocationInput] = useState<string>("");
  const [locationError, setLocationError] = useState<string | null>(null);

  // Function to reverse geocode latitude and longitude to get city and state
  const getCityState = async (lat: string, lon: string) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY; // Use your Google API key here
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`
      );

      if (!response.ok) {
        throw new Error("Failed to reverse geocode coordinates");
      }

      const data = await response.json();
      if (data.status === "OK") {
        const addressComponents = data.results[0]?.address_components || [];
        const city = addressComponents.find((comp: any) =>
          comp.types.includes("locality")
        )?.long_name;
        const state = addressComponents.find((comp: any) =>
          comp.types.includes("administrative_area_level_1")
        )?.long_name;

        if (city && state) {
          setCityState(`${city}, ${state}`);
        } else {
          setCityState("Location not found");
        }
      } else {
        setCityState("Location not found");
      }
    } catch (error: unknown) {
      setCityState("Error retrieving city and state");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Function to get latitude and longitude of the input location
  const handleLocationSubmit = async () => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY; // Use your Google API key here
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          locationInput
        )}&key=${apiKey}`
      );

      if (!response.ok) {
        throw new Error("Unable to find the location. Please try again.");
      }

      const data = await response.json();
      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        setUserLat(location.lat.toString());
        setUserLon(location.lng.toString());
        setLocationError(null); // Clear any previous errors

        // Fetch city and state using reverse geocoding
        getCityState(location.lat.toString(), location.lng.toString());
      } else {
        setLocationError("Unable to find the location. Please try again.");
      }
    } catch (error: unknown) {
      setLocationError("An unknown error occurred.");
    }
  };

  useEffect(() => {
    if (userLat && userLon) {
      getCityState(userLat, userLon);
    }
  }, [userLat, userLon]);

  return (
    <div className="flex flex-col items-center justify-center text-center py-12">
      {/* Image */}
      <img
        src="no_shows.webp" // Update the path to the correct location of the image
        alt="No shows available"
        className="w-[200px] h-auto rounded-lg"
      />

      {/* Message */}
      <h2 className="text-2xl font-bold text-zinc-950 mt-6 px-2 text-center">
        {loading ? "Checking your location..." : `No shows in ${cityState}`}
      </h2>

      {/* Location Input */}
      <div className="mt-6 w-full max-w-md px-4">
        <input
          type="text"
          value={locationInput}
          onChange={(e) => setLocationInput(e.target.value)}
          placeholder="Enter a new location"
          className="w-full px-4 py-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500 focus:border-indigo-500 shadow-sm"
        />
        <button
          onClick={handleLocationSubmit}
          className="px-4 py-2 mt-4 rounded-md bg-zinc-950 text-white border border-zinc-700"
        >
          Set Location
        </button>
        {locationError && (
          <p className="mt-2 text-sm text-red-600">{locationError}</p>
        )}
      </div>
      
      <p className="text-sm text-zinc-400 mt-2 max-w-lg">
        Follow us and we'll let you know when we're in your area!
      </p>

      {/* Social links */}
      <div className="flex gap-4 mt-4">
        <a
          href="https://www.facebook.com/profile.php?id=61565715673369"
          className="flex items-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="icon-facebook-color.png"
            alt="Facebook"
            className="w-8 h-8 mr-2 rounded-full" // Adjust size and spacing
          />
        </a>
        <a
          href="https://www.instagram.com/myvibelive/"
          className="flex items-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="icon-instagram-color.jpg"
            alt="Instagram"
            className="w-8 h-8 mr-2 rounded-full" // Adjust size and spacing
          />
        </a>
        <a
          href="https://www.tiktok.com/@myvibe.live"
          className="flex items-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="icon-tiktok-color.png"
            alt="TikTok"
            className="w-8 h-8 mr-2 rounded-full" // Adjust size and spacing
          />
        </a>
      </div>
    </div>
  );
};

export default NoShows;